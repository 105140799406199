import type { LanguageSwitchLinkFragment } from '#graphql-operations'

type QueryWithLanguageLinks = {
  route?:
    | {
        languageSwitchLinks?: LanguageSwitchLinkFragment[]
      }
    | object
}

export function setLanguageLinksFromFragment(
  links: LanguageSwitchLinkFragment[],
) {
  const route = useRoute()

  definePageLanguageLinks(
    route.path,
    links.reduce<Record<string, string>>((acc, v) => {
      if (v.language.id && v.url.path) {
        acc[v.language.id] = v.url.path
      }
      return acc
    }, {}),
  )
}

export function setLanguageLinksFromRoute(
  query?: QueryWithLanguageLinks | undefined | null,
) {
  if (query && query.route && 'languageSwitchLinks' in query.route) {
    setLanguageLinksFromFragment(query.route.languageSwitchLinks ?? [])
    return
  }
  const route = useRoute()
  definePageLanguageLinks(route.path, {})
}
